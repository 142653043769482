import React, { Component } from 'react'
import { connect } from 'react-redux'

import Header from './Header'
import Menu from './Menu'
import Loader from './Loader'
import Products from './Products'

class Root extends Component {
  render() {
    if (this.props.isFetching) {
      return <Loader className='loader' />
    }

    return (
      <>
        {/* <Loader className='loader animate__animated animate__bounceOutUp' /> */}
        <Header />
        <Menu />
        <Products />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.productsReducer.isFetching,
    error: state.productsReducer.error
  }
}

export default connect(mapStateToProps)(Root)