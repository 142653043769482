  
import { combineReducers } from 'redux'

import productsReducer from './productsReducer'
import tabReducer from './tabReducer'
import tableReducer from './tableReducer'

const rootReducer = combineReducers({
    productsReducer,
    tabReducer,
    tableReducer
})

export default rootReducer