import React, { Component } from 'react'
import { connect } from 'react-redux'

import searchButton from '../assets/img/search-button.png'

import { setSearchTerm } from '../store/actions/products'

class Searchbar extends Component {
  render() {
    const { searchTerm, tableToken } = this.props    

    return (
      <div className='searchbar'>
        <input type='text' name="term" onChange={e => this.props.setSearchTerm(e.target.value)} placeholder='Buscar no cardápio' value={tableToken} />
        <img src={searchButton} className='searchbar-icon' alt='Buscar' />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    searchTerm: state.productsReducer.searchTerm,
    tableToken: state.tableReducer.tableToken
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSearchTerm: term => dispatch(setSearchTerm(term))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Searchbar)