import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

class ProductOpened extends Component {
  state = {
    name: '',
    description: '',
    price: null,
    quantity: 1
  }

  componentDidMount() {
    this.getProductInfo(parseInt(this.props.index))
  }

  getProductInfo = index => {
    this.props.products.forEach(categories => {
      categories.items.forEach(item => {
        if (item.id === index) {
          this.setState({
            name: item.name,
            description: item.description,
            price: this.getPrice(item.ncr_id)
          })
        }
      })
    })
  }

  getPrice = index => {
    let price = this.props.prices.filter(val => {
      return val.id === index
    })

    if (price.length) {
      return price[0].price.toFixed(2)
    }

    return false
  }

  addQuantity = () => {
    this.setState({
      quantity: this.state.quantity + 1
    })
  }

  removeQuantity = () => {
    if (this.state.quantity > 1) {
      this.setState({
        quantity: this.state.quantity - 1
      })
    }
  }

  render() {
    return (
      <div className='product-modal'>
        <Link className='no-decoration product-info' to={`/`}>Voltar</Link>
        <div className='product-image'></div>
        <div className='product-content'>
          <div className='product-details'>
            <p className='name'>{this.state.name}</p>
            <p className='description'>{this.state.description}</p>
            <p className='price'>R$ {this.state.price}</p>
            <textarea rows='3' className='obs' placeholder='Ex: sem cebola'>
              
            </textarea>
          </div>
          <div className='product-footer-bar'>
            <div className='product-quantity'>
              <span className='signal' onClick={this.removeQuantity}>-</span>
              <span>{this.state.quantity}</span>
              <span className='signal' onClick={this.addQuantity}>+</span>
            </div>
            <div className='product-buy'>
              <span>Pedir</span>
              <span>R$ {(this.state.price * this.state.quantity).toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    products: state.productsReducer.products,
    prices: state.productsReducer.prices
  }
}

export default connect(mapStateToProps)(ProductOpened)