import React from 'react'
import { useLocation } from 'react-router-dom'

import ProductOpened from './ProductOpened'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function ProductInfo() {
  let query = useQuery()

  return (
    <ProductOpened index={query.get('id')} />
  )
}

export default ProductInfo