import React from 'react'

import banner from '../assets/img/banner.png'
import logo from '../assets/img/logo.png'
import MesaLogada from '../components/MesaLogada'

const Header = () => (
  <header style={{ backgroundImage: `url(${banner})` }}>
    <img src={logo} className='logo' />
    <MesaLogada/>
  </header>
)

export default Header