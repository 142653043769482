import React from 'react'

import logoLoader from '../assets/img/logo-loader.png'

const Loader = props => (
  <div className={props.className}>
    <div className='loader-image-group'>
      <img src={logoLoader} className='loader-image' />
    </div>
  </div>
)

export default Loader