import React from 'react'

import Categories from './Categories'
import Searchbar from './Searchbar'

const Menu = () => (
  <>
    <Categories />
    <Searchbar />
  </>
)

export default Menu