import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import DefaultImage from '../placeholders/DefaultImage'
import GarrafasImage from '../placeholders/GarrafasImage'
import LongNeckImage from '../placeholders/LongNeckImage'
import ChoppImage from '../placeholders/ChoppImage'
import EspeciaisImage from '../placeholders/EspeciaisImage'
import DrinksImage from '../placeholders/DrinksImage'
import WhiskiesImage from '../placeholders/WhiskiesImage'
import VodkasImage from '../placeholders/VodkasImage'
import CachacasImage from '../placeholders/CachacasImage'
import GinsImage from '../placeholders/GinsImage'
import LicoresImage from '../placeholders/LicoresImage'
import RumImage from '../placeholders/RumImage'
import TequilaImage from '../placeholders/TequilaImage'
import SaqueImage from '../placeholders/SaqueImage'
import DestiladosImage from '../placeholders/DestiladosImage'
import BrancosImage from '../placeholders/BrancosImage'
import RosesImage from '../placeholders/RosesImage'
import TintosImage from '../placeholders/TintosImage'
import EspumanteImage from '../placeholders/EspumanteImage'
import { PriceLoader } from '../placeholders/PriceLoader'

// import dots from '../../assets/img/dots.png'

class Child extends Component {
  getPrice = product_id => {
    let price = this.props.prices.filter(val => {
      return val.id === product_id
    })

    if (price.length) {
      return price[0].price.toFixed(2)
    }

    return false
  }

  renderPrice() {
    if (this.props.ncrId) {
      return <p className='product-price'>R$ {this.getPrice(this.props.ncrId)}</p>
    }

    return <PriceLoader />
  }
  
  render() {
    return (
      <div className={this.props.classes}>
        <div className='product-image'>
        {/* <Link className='no-decoration product-info' to={`/produto?id=${this.props.index}`}> */}
        <Link className='no-decoration product-info' to={`/#`}>
          {(() => {
            if (this.props.category === "Garrafas") {
              return <GarrafasImage />;
            } else if (this.props.category === "Long Neck") {
              return <LongNeckImage />;
            } else if (this.props.category === "Chopp") {
              return <ChoppImage />;
            } else if (this.props.category === "Especiais") {
              return <EspeciaisImage />;
            } else if (this.props.category === "Drinks Autorais") {
              return <DrinksImage />;
            } else if (this.props.category === "Whiskies") {
              return <WhiskiesImage />;
            } else if (this.props.category === "Vodkas") {
              return <VodkasImage />;
            } else if (this.props.category === "Cachaças") {
              return <CachacasImage />;
            } else if (this.props.category === "Gins") {
              return <GinsImage />;
            } else if (this.props.category === "Licores") {
              return <LicoresImage />;
            } else if (this.props.category === "Rum") {
              return <RumImage />;
            } else if (this.props.category === "Tequila") {
              return <TequilaImage />;
            } else if (this.props.category === "Saque") {
              return <SaqueImage />;
            } else if (this.props.category === "Destilados") {
              return <DestiladosImage />;
            } else if (this.props.category === "Brancos") {
              return <BrancosImage />;
            } else if (this.props.category === "Roses") {
              return <RosesImage />;
            } else if (this.props.category === "Tintos") {
              return <TintosImage />;
            } else if (this.props.category === "Espumante") {
              return <EspumanteImage />;
            } else {
              return <DefaultImage />;
            }
          })()}
        </Link>
        </div>
        {/* <Link className='no-decoration product-info' to={`/produto?id=${this.props.index}`}> */}
        <Link className='no-decoration product-info' to={`/#`}>
          <p className='product-name'>{this.props.name}</p>
          <p className='product-description'>{this.props.description}</p>
          <div className='product-footer'>
            {this.renderPrice()}
            {/* <img src={dots} alt='Abrir produto' /> */}
          </div>
        </Link>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    prices: state.productsReducer.prices
  }
}

export default connect(mapStateToProps)(Child)