const INITIAL_STATE = {
    tableId: 0,
    tabbleToken: 99
  }
  
  export default function tab(state = INITIAL_STATE, action) {
    switch (action.type) {
      case 'SET_TABLE':
        return {
          tableId: action.payload.tableId,
          tableToken: action.payload.tableToken
        }
      default: return state
    }
  }