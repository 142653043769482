import React, { Component } from 'react'
import { connect } from 'react-redux'


class MesaLogada extends Component {


  render() {
    if (1==1){ 
      return (
        <div className='MesaLogada'>
          {/* MESA | 02 */}
        </div>
      )
    }else{
      return (
        <div className='MesaLogada'>
          {/* MESA | 03 */}
        </div>
      )
    }
    
  }
}

const mapStateToProps = state => {
  return {
    // products: state.productsReducer.products
  }
}

export default connect(mapStateToProps)(MesaLogada)