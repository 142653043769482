import React, { Component } from 'react';
import { connect } from 'react-redux';
import Product from './Product';

class Products extends Component {
  state = {
    sortKey: 'name',
    sortOrder: 'asc'
  };

  getPrice = product_id => {
    let price = this.props.prices.filter(val => {
      return val.id === product_id;
    });

    if (price.length) {
      return price[0].price;
    }

    return false;
  };

  sortProducts = (products) => {
    const { sortKey, sortOrder } = this.state;    
    let sortedProducts = [...products];

    sortedProducts.forEach(productCategory => {
      productCategory.items.sort((a, b) => {
        if (a[sortKey] < b[sortKey]) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (a[sortKey] > b[sortKey]) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      });
    });

    return sortedProducts;
  };

  renderProducts = () => {
  const { products, searchTerm } = this.props;
  const sortedProducts = this.sortProducts(products);

  return sortedProducts.map((product, catKey) => {
    return product.items.map((val, prodKey) => {
      if (searchTerm && !val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return <div key={val.id}></div>;
      }

      return (
        <Product
          name={val.name}
          description={val.description}
          category={product.title}
          categoryType={product.category_type}
          catKey={catKey}
          prodKey={prodKey}
          price={this.getPrice(val.ncr_id)}
          key={val.id}
          options={val.options}
          index={val.id}
        />
      );
    });
  });
};

render() {
    return (
      <div className='product-list'>
        {this.renderProducts()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: state.productsReducer.products,
    prices: state.productsReducer.prices,
    searchTerm: state.productsReducer.searchTerm,
    category_id: state.tabReducer.category_id,
    tab_id: state.tabReducer.tab_id
  };
};

export default connect(mapStateToProps)(Products);
