import axios from 'axios'

axios.defaults.headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}
axios.defaults.timeout = 15000

export const endpoint = 'https://api-aloha.botecoparo.com.br/'
//export const endpoint = 'https://localhost'

const api = axios.create({
  baseURL: endpoint + 'api/'
})

export default api